import { React, useState, useEffect } from "react"
import { Link, navigate } from "gatsby"
import { Row, Col, Container, Form, Button } from "react-bootstrap"
import { firestore, useFirestoreDoc } from "gatsby-theme-firebase"

import Layout from "../components/layout"

const User = ({location}) => {
  var userId = '123';
  if (location.state) {
    userId = location.state.userId;
  }
  const [data, isLoading] = useFirestoreDoc(
    firestore.collection("users").doc(userId),
  );

  const[firstName, setFirstName] = useState(null)
  const[lastName, setLastName] = useState(null)
  const[phone, setPhone] = useState(null)
  const[category, setCategory] = useState(null)
  const[isActive, setIsActive] = useState(null)
  const[lists, setLists] = useState(null)

  var state = {
    userId : userId,
  }

  useEffect(
    () => {
      console.log(userId)
      const unsubscribe = firestore.collection('users').doc(state.userId).onSnapshot( doc => { 
        const data = doc.data()
        setFirstName(data.First)
        setLastName(data.Last)
        setPhone(data.Phone)
        setCategory(data.Type)
        setIsActive(data.IsActive)
        setLists(data.lists)
      } )
      // returning the unsubscribe function will ensure that
      // we unsubscribe from document changes when our id
      // changes to a different value.
      return () => unsubscribe()
    },
    [state.userId]
  )

  const handleSelectChange = event => {
    const target = event.target
    const options = target.options
    var value = []
    for (var i = 0, l = options.length; i < l; i++) {
      if (options[i].selected) {
        value.push(options[i].value)
      }
    }
    setLists(value)
  }
  
  const handleSubmit = event => {
    event.preventDefault()

    alert(`test: ${firstName} ${lastName} ${phone} ${category} ${isActive} ${lists}`)

    var userIsActive = ''
    if (isActive == true || isActive === 'on') 
      userIsActive = true
    else
      userIsActive = false

    firestore.collection("users").doc(userId).set({
      First : firstName,
      Last : lastName,
      Phone : phone,
      IsActive : userIsActive,
      Type : category,
      lists : lists
    })

    navigate('/admin')
  }

  return (
    <Layout>
      <Container>
        <Row className="justify-content-center my-3">
            <Col md="6">
              {isLoading && <p>Loading..</p>}
              {data &&
                <Form onSubmit={handleSubmit}>
                  <Form.Group controlId='formFirst'>
                    <Form.Label>First Name</Form.Label>
                    <Form.Control type='text' name='firstName' value={data.First} defaultValue={data.First} onChange={e => setFirstName(e.target.value)} ></Form.Control>
                  </Form.Group>

                  <Form.Group controlId='formLast'>
                    <Form.Label>Last Name</Form.Label>
                    <Form.Control type='text' name='lastName' defaultValue={data.Last} onChange={e => setLastName(e.target.value)} ></Form.Control>
                  </Form.Group>

                  <Form.Group controlId='formPhone'>
                    <Form.Label>Phone</Form.Label>
                    <Form.Control type='number' name='phone' defaultValue={data.Phone} onChange={e => setPhone(e.target.value)} ></Form.Control>
                  </Form.Group>

                  <Form.Group controlId='formType'>
                    <Form.Label>Category</Form.Label>
                    <Form.Control type='text' name='category' defaultValue={data.Type} onChange={e => setCategory(e.target.value)} ></Form.Control>
                  </Form.Group>

                  <Form.Group controlId='formIsActive'>
                    <Form.Label>Is Active</Form.Label>
                    <Form.Check name='isActive' defaultChecked={data.IsActive} onChange={e => setIsActive(e.target.value)} ></Form.Check>
                  </Form.Group>

                  <Form.Group controlId='formLists'>
                    <Form.Label>Lists</Form.Label>
                    <Form.Control as='select' multiple name='lists' onChange={handleSelectChange} >
                      <option value='team' selected={(data.lists != null && data.lists.includes('team')) ? true : false} >team</option>
                      <option value='pitchers' selected={(data.lists != null && data.lists.includes('pitchers')) ? true : false} >Pitchers</option>
                      <option value='positionplayers' selected={(data.lists != null && data.lists.includes('Position Players')) ? true : false} >Position Players</option>
                      <option value='coaching' selected={(data.lists != null && data.lists.includes('coaching')) ? true : false} >Coaching</option>
                      <option value='medical' selected={(data.lists != null && data.lists.includes('medical')) ? true : false} >Medical</option>
                      <option value='frontoffice' selected={(data.lists != null && data.lists.includes('frontoffice')) ? true : false} >Front Office</option>
                      <option value='clubhouse' selected={(data.lists != null && data.lists.includes('clubhouse')) ? true : false} >Clubhouse</option>
                      <option value='broadcasting' selected={(data.lists != null && data.lists.includes('broadcasting')) ? true : false} >Broadcasting</option>
                    </Form.Control>
                  </Form.Group>

                  <Button variant="primary" type="submit" >Submit</Button>
                </Form>
            }
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export default User